<template>
  <div>
    <el-row :gutter='10'>
      <el-col :span='16'>
        <PlatformsRadioGroup :platformCode='searchCondition.platform_code'
                             @choicePlatform='choicePlatform'></PlatformsRadioGroup>
      </el-col>
      <el-col :span='8' style='text-align: right'>
        <export-link ref='refExportLinkAll' @export='exportExcel("refExportLinkAll",true)'
                     :can-export="userPermissions.indexOf('dashboard_trend_export') > -1" :lint-title='`导出全平台`' />
        <export-link ref='refExportLink' @export='exportExcel("refExportLink")'
                     :can-export="userPermissions.indexOf('dashboard_trend_export') > -1" :lint-title='`导出`' />
        <ImageShare v-if="userPermissions.indexOf('dashboard_trend_share') !== -1" :share-content-id='`shareContent`'
                    :btn-title='`分享榜单`' :dialog-title='`涨粉榜单分享图`' />
        <el-link :underline='true' type='primary' class='share-rank' @click='goTrendDataList'
                 v-if="userPermissions.indexOf('trend_data') !== -1"><i class='el-icon-s-grid'></i>详情
        </el-link>
        <el-tooltip class='item' effect='dark' content='无权限：查看涨粉数据列表' placement='left-start' v-else>
          <el-link :underline='false' type='info' class='export-excel'>
            <i class='el-icon-s-grid'></i>详情
          </el-link>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-row :gutter='10'>
      <el-col :span='24'>
        <el-form ref='searchForm' size='mini' :inline='true' :model='searchCondition'>
          <el-form-item>
            <CompanySelect ref='refCompanySelect' :multiple='true' @handleSelect='selectDept' />
          </el-form-item>
          <el-form-item label=''>
            <el-radio-group v-model='checkedRadio' @change='radioChange'>
              <el-radio-button label='1'>昨天</el-radio-button>
              <el-radio-button label='7'>近7日</el-radio-button>
              <el-radio-button label='30'>近30日</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label=''>
            <el-date-picker
              v-model='ym'
              type='month'
              value-format='yyyy-MM'
              placeholder='选择月份' style='width: 120px' @change='changeMonth'>
            </el-date-picker>
          </el-form-item>
          <el-form-item label=''>
            <el-date-picker
              v-model='rangYm'
              type='monthrange'
              align='right'
              unlink-panels
              range-separator='至'
              start-placeholder='开始月份'
              end-placeholder='结束月份'
              value-format='yyyy-MM'
              :picker-options='pickerOptions' @change='changeMonths'>
            </el-date-picker>
          </el-form-item>
          <el-form-item label=''>
            <el-date-picker
              v-model='searchCondition.time_value'
              type='daterange'
              align='right'
              unlink-panels
              range-separator='至'
              start-placeholder='开始日期'
              end-placeholder='结束日期'
              value-format='yyyy-MM-dd' @change='changeTime'>
            </el-date-picker>
          </el-form-item>
          <el-form-item label=''>
            <el-select v-model='filterArtist' filterable multiple collapse-tags placeholder='请选择检索红人' clearable>
              <el-option
                v-for='item in tableData'
                :key='item.artist_id'
                :label='item.nickname'
                :value='item.artist_id'>
              </el-option>
            </el-select>
          </el-form-item>
          <!--          <el-form-item>-->
          <!--            <el-button type='primary' @click='initData'>搜索</el-button>-->
          <!--            <el-button type='default' @click='reset'>重置</el-button>-->
          <!--          </el-form-item>-->
        </el-form>
      </el-col>

    </el-row>
    <!--    表格-->
    <div id='shareContent'>
      <!--      ！！！！ 不限制表格高度， 避免生成分析图片不完整-->
      <div class='default-table'
           v-loading='loadingStatus'
           element-loading-text='数据加载中……'
           element-loading-spinner='el-icon-loading'>
        <el-table
          :data='tableData'
          :default-sort="{prop:'total_num', order:'descending'}"
          :row-class-name='rowClassName'
          border
          :summary-method='getSummaries'
          show-summary
          style='width: 100%;'>
          <el-table-column
            prop='index'
            width='64'
            label='序号' align='center' fixed='left'>
          </el-table-column>
          <el-table-column
            prop='group_name'
            label='部门-组'
            width='200'
            align='center' header-align='center' sortable>
            <template slot-scope='{row}'>
              <el-link type='primary' @click='goDeptDetail(row.group_id)'
                       v-if="userPermissions.indexOf('dept_detail')>-1"
                       target='_blank'>
                {{ row.group_name || '' }}
              </el-link>
              <span v-else>{{ row.group_name || '' }}</span>

              <!--              <DeptNameTag :info='{id:row.group_id,name:row.group_name}' />-->
            </template>
          </el-table-column>
          <el-table-column
            prop='nickname'
            label='红人昵称'
            width='160'
            align='center' header-align='center' show-overflow-tooltip sortable>
            <template slot-scope='{row}'>
              <!--              <artist-nickname :info='scope.row' />-->
              <el-link :underline='true' type='primary' @click='jumpToKolDetail(row.artist_id)' title='点击查看【红人详情】'>
                {{ row.nickname }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
            prop='duration'
            label='合作时长'
            min-width='100' align='center' sortable>
            <template slot-scope='scope'>
              <el-tooltip class='item' effect='dark'
                          :content="`签约时间：${scope.row.sign_date?$utils.parseTime(scope.row.sign_date, '{y}/{m}/{d}'):''}`"
                          placement='top'>
                <span>{{ scope.row.duration_alias }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label='总粉丝数' prop='total_num' width='140' header-align='center'
                           align='right' sortable></el-table-column>
          <el-table-column label='新增粉丝' prop='add_num' width='140' header-align='center' align='right' sortable>
            <template slot-scope='{row,column}'>
              <span title='录入与计算所得(内容增粉+推广增粉)存在差异' v-if='row.add_num != row._add_num'
                    :class='[row["_add_num"]-row["add_num"]>0?"calc-up":"calc-down"]'>
                ({{ row['_add_num'] - row['add_num'] > 0 ? '+' : '' }}{{ numberFormat(row['_add_num'] - row['add_num'])
                }})
              </span>
              <span> {{ numberFormat(row[column.property] || 0) }}</span>
            </template>
          </el-table-column>
          <!--          <el-table-column label='_add_num' prop='_add_num' width='100' v-show='false'></el-table-column>-->
          <template v-for='(column,index) in columns'>
            <el-table-column :prop='`${column.value}`' :key='index' :label='`${column.title}`'
                             :min-width='`${column.width}`' align='right' header-align='center' sortable>
              <template slot-scope='scope'>
                  <span v-if="column.unit==='money'">{{
                      moneyFormat(scope.row[column.value] || 0)
                    }}</span>
                <span v-else-if="column.unit==='number'">{{
                    numberFormat(scope.row[column.value] || 0)
                  }}</span>
                <span v-else>{{ scope.row[column.value] }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
    </div>

    <div style='padding: 5px;font-style: italic'>
      录入的新增涨粉数量<span>(合计：{{ add_num_total }})</span>和计算所得（内容增粉+推广增粉）<span>（合计：{{ calc_add_num_total
      }}）</span>存在差异,差值：<span
      :class='[calc_add_num_total - add_num_total>0?"calc-up":"calc-down"]'>{{ calc_add_num_total - add_num_total
      }}</span>
    </div>

    <!--    备注-->
    <Tips :data='tipsList' />
  </div>
</template>

<script>
import FileSaver from 'file-saver'
import { mapGetters } from 'vuex'
import PlatformsRadioGroup from '@/pages/platform/components/PlatformsRadioGroup'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import { numberFormat } from '@/utils/utils'
import ExportLink from '@/components/export/ExportLink'
import DeptSelect from '@/pages/departments/DeptSelect'

export default {
  name: 'TrendChart',
  components: {
    DeptSelect,
    ExportLink,
    PlatformsRadioGroup,
    PageHeaderLayout
  },
  data() {
    return {
      loadingStatus: false,
      checkedRadio: 1,
      searchCondition: {
        depth: null,
        group_id: null,
        platform_code: null,
        time_value: []
      },
      ym: null,
      rangYm: null,
      tableData: [],
      platforms: [],
      checkAll: false,
      checkedPlatforms: [],
      isIndeterminate: true,
      columns: [
        // { value: 'total_num', title: '总粉丝数', width: 100, unit: 'number' },
        // { value: 'add_num', title: '新增粉丝', width: 80, unit: 'number' },
        // { value: '_add_num', title: '新增粉丝', width: 80, unit: 'number' },
        { value: 'content_add_num', title: '内容增粉', width: 80, unit: 'number' },
        { value: 'promotion_add_num', title: '推广增粉', width: 80, unit: 'number' },
        { value: 'promotion_cost', title: '涨粉投放', width: 80, unit: 'money' },
        { value: 'price', title: '粉丝单价', width: 80, unit: 'money' },
        { value: 'ad_repair', title: '广告维护', width: 80 },
        { value: 'live_preheat', title: '直播预热', width: 80 },
        { value: 'add_works_num', title: '新增作品', width: 80, unit: 'number' },
        { value: 'total_works_num', title: '总作品数', width: 80, unit: 'number' }
      ],
      lastDate: '',
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 5)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      explainList: [
        { title: '默认1', explain: '金额展示默认保留2位小数', 'auth': '' },
        { title: '默认2', explain: '数值以千分位的形式格式化', 'auth': '' },
        { title: '合计涨粉单价', explain: '粉丝单价 = 推广花费 / 推广增粉数', 'auth': '熊灿' },
        { title: '涨粉单价', explain: '(每行)粉丝单价 = 推广花费 / 推广增粉数', 'auth': '' },
        { title: '未录入标记', explain: '若榜单数据行背景色为橙色，则代表该项数据未录入', 'auth': '熊灿' },
        { title: '红人检索', explain: '榜单红人检索，按照红人昵称检索到指定红人，方便查看 2021/06/15', 'auth': '胡杨' },
        { title: '总粉丝数和总作品数', explain: '总粉丝数和总作品数：筛选时间范围的最后一天，若无录入数据，则为0 （2021/08/11）', 'auth': '季建贵' },
        { title: '合作时长', explain: '合作时长：若未解约，按照当前日期和签约日期的月份差计算，若已解约，则按照解约时间和签约时间计算（2021/08/11）', 'auth': '季建贵' }
      ],
      filterArtist: [],
      calc_add_num_total: 0,//计算涨粉合计
      add_num_total: 0//录入涨粉合计
    }
  },
  computed: {
    ...mapGetters(['userPermissions','userDptInfo']),
    tipsList() {
      let list = []
      this.explainList.forEach((row) => {
        list.push(`${row.explain} ${row.auth ? '@' : ''}${row.auth}`)
      })
      return list
    }
  },
  created() {

  },
  mounted() {
    this.getLastDate()
  },
  methods: {
    init() {
      this.getPlatforms()
      this.initData()
    },
    moneyFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    numberFormat(value) {
      return numberFormat(value, 0, '.', ',', 'round')
    },
    rowClassName({ row, rowIndex }) {
      // 把每一行的索引放进row.id
      row.index = rowIndex + 1
      //显示或隐藏判断

      row.isShow = this.filterArtist && this.filterArtist.length > 0 ? this.filterArtist.indexOf(row.artist_id) > -1 : true
      if (!row.isShow) {
        return 'hidden-columns'//table.css
      }

      if (row.is_empty) {
        return 'warning-row'
      } else if (rowIndex === 3) {
        return 'success-row'
      }
      return ''
    },
    async getPlatforms() {
      //从缓存中读取上次的平台信息
      let platformsJson = localStorage.getItem('platforms')
      if (platformsJson && platformsJson.indexOf('{') > -1) {
        this.platforms = JSON.parse(platformsJson)
      }

      // 获取最新平台信息
      let { list } = await this.$api.getPlatforms()
      this.platforms = list
      // 缓存平台信息
      localStorage.setItem('platforms', JSON.stringify(list))

      //默认第一个平台,单选
      if (this.platforms.length > 0) {
        this.searchCondition.platform_code = list[0]['code']

        await this.initData()
      }
    },
    getLastDate() {
      //昨天的时间
      this.lastDate = this.getLastDays(1)
      // 默认选择昨天
      this.choiceTimeData(1)
    },
    selectDept(nodeIds) {
      console.log(nodeIds)
      // const nodeData = this.$refs['refCompanySelect'].getNodeData()
      // console.log('=========== nodeData ==========')
      // console.log(nodeData)
      // nodeIds.forEach((nodeId) => {
      //   let curr_node = nodeData.find(value => value.dpt_id === nodeId)
      //   console.log(`node:${nodeId},name:${curr_node.name}`)
      // })
      // console.log('=========== user allow dept ==========')
      // console.log(this.userDptInfo)
      this.searchCondition.group_ids = nodeIds
      this.initData()
    },

    radioChange() {
      this.ym = null
      this.choiceTimeData(this.checkedRadio)
      this.initData()
    },
    getLastDays(days) {
      //几天前的时间
      let day1 = new Date()
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000 * days)
      return day1.getFullYear() + '-' + (day1.getMonth() + 1) + '-' + day1.getDate()
    },
    choiceTimeData(days) {
      //选择时间
      let beginTime = this.getLastDays(days)
      let endTime = this.getLastDays(1)
      this.searchCondition.time_value = [beginTime, endTime]
    },
    choicePlatform(val) {
      // console.log('searchCondition', this.searchCondition)
      this.searchCondition.platform_code = val
      this.initData()
    },
    async initData() {
      // console.log('init searchCondition', this.searchCondition)
      this.tableData = []

      let searchCondition = this.handleSearchCondition()
      // Object.assign(searchCondition, {})
      if (this.searchCondition.platform_code && this.searchCondition.time_value && this.searchCondition.time_value.length === 2) {
        this.loadingStatus = true
        //已录入的数据
        let { list } = await this.$api.getTrendDatumRankData(searchCondition)
        // console.log('list', list)
        this.calc_add_num_total = 0
        this.add_num_total = 0
        list.forEach(item => {
          //新增粉丝数(计算)=内容增粉数 + 推广增粉数
          item['_add_num'] = item['content_add_num'] / 1 + item['promotion_add_num'] / 1
          this.calc_add_num_total += item['_add_num'] / 1
          this.add_num_total += item['add_num'] / 1
        })

        this.tableData = list
        // console.log(this.tableData)
        this.loadingStatus = false
      }
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.depth) {
        condition.depth = this.searchCondition.depth
      }
      if (this.searchCondition.group_ids) {
        condition.group_ids = this.searchCondition.group_ids
      }

      if (this.searchCondition.platform_code) {
        condition.platform_code = this.searchCondition.platform_code
      }

      if (this.searchCondition.time_value) {
        condition.start_time = this.searchCondition.time_value[0]
        condition.end_time = this.searchCondition.time_value[1]
      }

      return condition
    },
    changeMonth() {
      this.rangYm = null
      this.checkedRadio = null
      if (this.ym) {
        let ymArr = this.ym.split('-')
        let day = new Date(parseInt(ymArr[0]), parseInt(ymArr[1]), 0)
        let daycount = day.getDate()//最后一天

        this.searchCondition.time_value = [this.ym + '-01', this.ym + '-' + daycount]

        this.initData()
      }

    },
    changeMonths() {
      if (this.rangYm) {
        this.ym = null
        this.checkedRadio = null
        let beginMonth = this.rangYm[0]
        let endMonth = this.rangYm[1]
        let ymArr = endMonth.split('-')
        let day = new Date(parseInt(ymArr[0]), parseInt(ymArr[1]), 0)
        let daycount = day.getDate()//最后一天
        this.searchCondition.time_value = [beginMonth + '-01', endMonth + '-' + daycount]

        this.initData()
      }
    },
    changeTime() {
      if (this.searchCondition.time_value) {
        this.ym = null
        this.rangYm = null
        this.checkedRadio = null
        this.initData()
      }
    },
    async exportExcel(refName, isAll) {
      try {
        let name = `涨粉榜单`
        let searchCondition = this.handleSearchCondition()
        if (isAll) {
          //若要导出全平台数据，则去掉平台条件
          delete searchCondition.platform_code
        }
        let response = await this.$api.downloadTrendDatum(searchCondition)
        if (this.$refs[refName])
          this.$refs[refName].exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    async exportDownload() {
      try {
        var isFileSaverSupported = !!new Blob
        // console.log('isFileSaverSupported', isFileSaverSupported)
        if (isFileSaverSupported) {
          let searchCondition = this.handleSearchCondition()
          let response = await this.$api.downloadTrendDatum(searchCondition)
          let data = response.data
          let contentType = response.headers['content-type']
          let name = '涨粉数据'
          let blob = new Blob([data], {
            type: contentType
          })
          FileSaver.saveAs(blob, decodeURI(name + '.xlsx'), { autoBom: true })
        } else {
          this.$message.warning('浏览器不支持导出文件')
        }
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }

    },
    async shareRank() {
      // Saving a canvas
      FileSaver.saveAs('https://httpbin.org/image', 'image.jpg')

    },
    jumpToDetail(row) {
      const keyword = 'DingTalk'
      if (navigator.userAgent.indexOf(keyword) > -1) {
        // 获取订单配置
        this.$router.push({
          name: 'artist_trend_chart',
          params: { artist_id: row.artist_id },
          query: { group_name: row.group_name }
        })
      } else {
        const { href } = this.$router.resolve({
          name: 'artist_trend_chart',
          params: { artist_id: row.artist_id },
          query: { group_name: row.group_name }
        })
        window.open(href, '_blank')
      }
    },
    goTrendDataList() {
      this.$router.push({
        path: '/trend/data'
      })
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      let price_index = null// 涨粉单价的索引
      let promotion_cost_index = null// 总推广花费的索引
      let promotion_add_num_index = null// 总推广增粉数的索引
      let ad_repair_index = null//广告维护的索引
      let live_preheat_index = null//直播预热的索引
      let add_num_index = null //涨粉数
      let _add_num_index = null//涨粉计算
      columns.forEach((column, index) => {
        if (index < 3) {
          return
        }
        if (index === 3) {
          sums[index] = '合计:'
          return
        }
        // console.log(`${index}）${column.property}`)
        if (['total_num', 'total_works_num', 'add_num', 'content_add_num', 'promotion_add_num', 'promotion_cost', 'add_works_num', 'ad_repair', 'live_preheat'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }

          // // 格式化数据
          switch (column.property) {
            case 'add_num':
              add_num_index = index
              break
            case '_add_num':
              _add_num_index = index
              break
            case 'promotion_cost':
              promotion_cost_index = index
              // console.log(`推广花费合计：${total_promotion_cost}`)
              // sums[index] = '¥' + this.moneyFormat(sums[index])
              break
            case 'promotion_add_num':
              promotion_add_num_index = index
              // console.log(`推广增粉合计：${total_promotion_add_num}`)
              break
            case 'ad_repair':
              ad_repair_index = index
              break
            case 'live_preheat':
              live_preheat_index = index
              break
            default:
              sums[index] = this.numberFormat(sums[index])
              break
          }
        }

        //涨粉单价列索引（）
        if (column.property === 'price') {
          price_index = index
        }
      })

      // 涨粉单价=推广花费/推广涨粉数
      if (price_index !== null && promotion_add_num_index !== null && promotion_cost_index !== null) {
        sums[price_index] = sums[promotion_add_num_index] > 0 ? sums[promotion_cost_index] / sums[promotion_add_num_index] : ''
        //格式化
        sums[promotion_cost_index] = '¥' + this.moneyFormat(sums[promotion_cost_index])
        sums[price_index] = '¥' + this.moneyFormat(sums[price_index])
        sums[promotion_add_num_index] = this.numberFormat(sums[promotion_add_num_index])
        sums[ad_repair_index] = '¥' + this.moneyFormat(sums[ad_repair_index])
        sums[live_preheat_index] = '¥' + this.moneyFormat(sums[live_preheat_index])
      }
      //显示计算所得总涨粉数
      if (add_num_index !== null && _add_num_index !== null) {
        sums[add_num_index] = `(${this.calc_add_num_total - sums[add_num_index] !== 0 ? this.calc_add_num_total : '/'} )${sums[add_num_index]}`
      }

      return sums
    },
    reset() {
      this.searchCondition = {
        depth: null,
        group_id: null,
        platform_code: null,
        time_value: []
      }
      this.$refs['refDeptCascader'].initVal(null)
      this.getLastDate()
      this.initData()
    },
    goDeptDetail(id) {
      if (id) {
        const { href } = this.$router.resolve({
          name: 'team',
          query: { group_id: id }
        })
        window.open(href, '_blank')
      } else {
        this.$notify.error('部门ID未知，无法查看详情')
      }
    },
    jumpToKolDetail(artist_id) {
      const { href } = this.$router.resolve({
        name: 'artist_intro',
        params: { artist_id: artist_id }
      })
      window.open(href, '_blank')
    }
  }
}
</script>

<style scoped>
.calc-up {
  color: #ff0020;
  font-weight: bold;
  font-size: 1.1em;
}

.calc-down {
  color: #0aab62;
  font-weight: bold;
  font-size: 1.2em;
}

.sign_date {
  color: #909399;
  font-weight: 400;
}

.export-excel, .share-rank {
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 1px;
  font-size: 16px;
  font-weight: 600;
}

</style>
<style>
.el-table .warning-row {
  background: #f9f6f1;
}

</style>
